import React from "react";
import RotationalSpaceMod from "../components/rotationalSpaceMod";
import LogoLeft from "../components/logoleft";

const SpaceModePage = () => {

    return (
        <>
        <RotationalSpaceMod>
            <LogoLeft/>
        </RotationalSpaceMod>
        </>
    )
}

export default SpaceModePage;