import React from "react";
import LogoLeft from "../components/logoleft";
import Freymoji from "../components/freymoji";

const FreymojiPage = () => {

    return (
        <>
            <Freymoji>
            <LogoLeft />
            </Freymoji>
        </>
    )
}

export default FreymojiPage;