import React from "react";
import RefractedRays from "../components/refractedRays";
import LogoLeft from "../components/logoleft";

const RefractedRaysPage = () => {

    return (
        <>
        <RefractedRays>
        <LogoLeft />

        </RefractedRays>
        </>
    )

};

export default RefractedRaysPage;